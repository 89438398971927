#cart h1{
    padding: 15px;
}

#cart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

#total-paypal {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

#total-container div{
    display: flex;
    justify-content: space-between;

}

#empty-cart {
    display: flex;
    flex-direction: column;
    justify-content: cent;
    padding: 10px
}

#empty-cart h4 {
    padding: 30px;
    font-size: 30px;
}

.align-left {
    align-self: flex-start !important;
}