#offerings {
    color: white;
    text-align: center;
    background-color:#EBA644;
}

#offerings p {
}

#offerings-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.offerings-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}

.offerings-block {
    color: black;
    background-color: white;
    width: 300px;
    aspect-ratio: 1;
    margin: 10px;
}