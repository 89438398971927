/* Add a background color to the top navigation */
.topnav, .topnav button {
    background-color: #EBA644;
    overflow: hidden;
  }
  
  /* Style the links inside the navigation bar */
  .topnav a , #hamburger-container{
    float: left;
    color: #775582;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  /* Change the color of links on hover */
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Add a color to the active/current link */
  .topnav a.active {
    background-color: #04AA6D;
    color: white;
  }

  #cart-navlink {
    float: right;
    position: relative;
  }

  @media screen and (min-width: 480px) {
    #hamburger-container {
      display: none !important;
    }
    #navlinks {
      display: block !important;
      border: none !important;
    }
  }

  #hamburger-container {
    display: block;;

  }
  #navlinks {
    display: none;
  }

  #hamburger-icon, #close-navbar-icon {
    border: none;
  }
    
  .topnav p {
    color: white;
    background-color: rgba(253, 44, 44, 0.527);
    border-radius: 50%;
    height: 30%;
    width: 30%;
    display: inline-block;
    position: absolute;
    top: 2px;
    right: 5px;
    font-size: 60%;
  }