.productPage_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 300px;
    max-width: 1000px;  
    /* border: 1px solid black;  */
    margin: auto;
    padding: 30px;

}

.productPage_container img {
    width: 100%; 
    padding: 5px;  
}

#proguctPage-continue-shopping {
    margin: 20px;
}

