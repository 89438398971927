/* Parchment background and handwriting font for the title and blurbs */
.events, .eventDate {
    background-image: url('../../resources/images/parchment_background.jpeg'); /* replace with your actual image path */
    background-color: #fdf5e6; /* Fallback for when the image is not loaded */
    color: #8B4513; /* Dark brown text for contrast */
    font-family: 'Dancing Script', cursive; /* Example of a handwriting-like font */
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #deb887; /* Light brown border to mimic old paper edges */
    box-shadow: 0px 4px 6px rgba(0,0,0,0.1); /* Soft shadow for a lifted paper effect */
}

.eventDate {
    background-image: url('../../resources/images/parchment_background.jpeg'); /* replace with your actual image path */
    background-color: #fdf5e6; /* Fallback for when the image is not loaded */
    color: #8B4513; /* Dark brown text for contrast */
    font-family: 'Dancing Script', cursive; /* Example of a handwriting-like font */
    padding: 20px;
    border: none;
}

.events h1 {
    font-size: 2.5rem; /* Larger font for the title */
}

.events h3 {
    font-size: 2rem; /* Larger font for the title */
}

.events p {
    font-size: 1.2rem; /* Comfortable reading size for blurbs */
}

.events .flex {
    justify-content: center; /* Center align the navigation buttons if used */
}

.events .btn-outline-primary {
    background-color: transparent;
    color: #8B4513; /* Dark brown to match the parchment theme */
    border-color: #deb887; /* Border color to match */
}

.events .btn-outline-primary:hover {
    background-color: #deb887; /* Light brown hover effect */
}

.eventDate {
    font-size: 1.5rem; /* Larger font for the title */
}