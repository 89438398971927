:root {
    --flexDirection: row;
    --textAlign: left; 
}


@media screen and (max-width: 750px) {
    :root {
        --flexDirection: column;
        --textAlign: center; 
    }

    .footer img {
        display: none;
    }
}

.header_color {
    background-color: #F3CF50;
    color: #735284;
}

.above_nav {
    display: flex;

    flex-direction: var(--flexDirection);
    align-items: center;

    justify-content: space-between;
    padding: 20px 10px;
}

.get_in_touch {
    display: flex;
    align-items: center;
    padding: 0px 20px;
}

.get_in_touch button {
    font-size: 15px;
    padding: 13px 22px;
    color: #F9F8FA;
    background-color: #735284;
}


.title_container {
    display: flex;

    flex-direction: var(--flexDirection);

    align-items: center;
    font: 22px barlow-medium, barlow, sans-serif;
}

.title h1 {
    block-size: auto;
    font: 22px barlow-medium, barlow, sans-serif;
    position: relative;

    text-align: var(--textAlign);
}

.title p {
    block-size: auto;
    font-size: 15px;
    position: relative;

    text-align: var(--textAlign);
}



.logo {
    display: flex;
    justify-content: center;
}

.logo img {
    padding: 10px;
    width: 40%;
}

#close-navbar-icon {
    display: none;
}