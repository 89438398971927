form{
    margin: 0 0 0 10px;
}

#send_button{
    margin: 10px 0;
}

label{
    margin: 10px 0 0 0;
}

