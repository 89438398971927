#banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    background-image: url('../../resources/images/background_farm.webp');
    background-attachment: fixed;
    background-size: cover;
    height: 120vh;
    /* background-position: right bottom; */
    /* background-position: -webkit-sticky; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

@media screen and (max-height: 480px) {
    #banner {
        height: 200vh !important;
    }
    
    #banner h1 {
        font-size: 10vh !important;
        padding: 20px;
    }

    #banner h4 {
        font-size: 6vh !important;
        line-height: 35px !important;
        padding: 10px
    }

    .banner_p p {
        font-size: 4.5vh !important;
        line-height: 28px !important;
    }

    .learn_more button {
        padding: 7px 12px !important;
    }
    
}

@media screen and (max-width: 700px) {
    #banner {
        background-image: url('../../resources/images/calabash-gardens-farm-mobile.png');
        
    }
    
    #banner h1 {
        font-size: 12vw !important;
        padding: 20px;
    }

    #banner h4 {
        font-size: 6vw !important;
        line-height: 35px !important;
        padding: 10px
    }

    .banner_p p {
        font-size: 4.5vw !important;
        line-height: 28px !important;
    }

    .learn_more button {
        padding: 7px 12px !important;
    }
    
}



#banner h1 {
    padding: 20px;
    font: 6vw barlow-medium, barlow, sans-serif;
    block-size: auto;
    margin: 0px;
}

#banner h4 {
    font: 3vw barlow-medium, barlow, sans-serif;
    line-height: 4vw;
    block-size: auto;

}

.banner_p p {
    font-size: 2vw;
    line-height: 3vw;
    block-size: auto;
    margin: 0px 15%;
}


.learn_more {
    display: none;
    padding: 20px;
}

.learn_more button {
    background-color: #F3CF50;
    padding: 15px 25px;
}







