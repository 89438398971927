
.footer_color {
    background-color: #735284;
    color: #F3CF50;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20vh; 
}

.get_in_touch_footer {
    display: flex;
    align-items: center;
    padding: 0px 20px;
}

.get_in_touch_footer button {
    color: #735284;
    background-color: #F3CF50;
    font-size: 15px;
    padding: 13px 22px;
}





