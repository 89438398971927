#parallax_container {
    background-image: url('../../resources/images/parallax.webp');
    position: -webkit-sticky;
    position: sticky;
    /* background-attachment: fixed; */
    -webkit-background-size: 150%;
    -moz-background-size: 150%;
    background-size: 150%;
    background-repeat: no-repeat;
    height: 60vh;
}

@media screen and (max-width: 700px) {

    #parallax_container {
        -webkit-background-size: 350%;
        -moz-background-size: 350%;
        background-size: 350%;    }
}
