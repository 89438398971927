#team {
    color: white;
    text-align: center;
    background-color: #EBA644;
    padding: 40px;
}

#team h1 {
    padding: 20px 0 0 0;

}

#team p {
    font-family: avenir-lt-w01_35-light1475496, avenir-lt-w05_35-light, sans-serif;
    font-size: 15px;
    line-height: 28px;
}

#team_main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}



#position_1_container {
    display: flex;
    min-width: 200px;
    max-width: 500px;
    border-top: 1px solid white;
    padding: 15px 0 0 0;
}

#position_2_container {
    display: flex;
    min-width: 200px;
    max-width: 500px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 15px 0 0 0;

}

#position_1_container img {
    padding: 20px;
}

#position_2_container img {
    padding: 20px;
}

#position_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#position_2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 480px) {
    #team {
        padding: 0;
    }

    #team_main {
        padding: 25px 15px;
    }

    #team p {
        font-family: avenir-lt-w01_35-light1475496, avenir-lt-w05_35-light, sans-serif;
        font-size: 14px;
        line-height: 18px;
        padding: 10px;
    }

    #position_1_container img {
        width: 33%;
    }
    
    #position_2_container img {
        width: 33%;
    }

}